@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~quill/dist/quill.core.css";

.main {
    overflow-x: auto;
    overflow-y: hidden;
    height: 100vh;
    .sidebar {
        &.expanded {
            width: 280px;
        }
    }
    .content {
        width: calc(100% - 280px);
        overflow-y: scroll;
    }
}
